<template>
  <v-container fluid class="grey lighten-3">

    <v-card class="mb-5">

        <v-tabs v-model="tab" @change="tabChanged">
          <v-tab v-for="item in items" :key="item">{{ item }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <ReviewData v-if="tab == 0"></ReviewData>
          </v-tab-item>
          <v-tab-item>
            <RefreshData v-if="tab == 1"></RefreshData>
          </v-tab-item>
          <v-tab-item>
            <MakeChanges v-if="tab == 2"></MakeChanges>
          </v-tab-item>
        </v-tabs-items>

    </v-card>

  </v-container>
</template>

<script>
export default {
  name: 'KeywordRelocation',

  data () {
    return {
      tab: null,
      items: ['Review', 'Refresh', 'Make changes'],
      lsTab: 'kwRelocation_tab',
    }
  },

  components: {
    MakeChanges: () => import('@/components/keywordRelocation/MakeChanges.vue'),
    ReviewData: () => import('@/components/keywordRelocation/ReviewData.vue'),
    RefreshData: () => import('@/components/keywordRelocation/RefreshData.vue'),
  },

  methods: {
    tabChanged () {
      localStorage.setItem(this.lsTab, JSON.stringify(this.tab))
    },
  },

  created: function () {
    if (localStorage.getItem(this.lsTab)) {
      this.tab = JSON.parse(localStorage.getItem(this.lsTab))
    }
  },
}
</script>

<style scoped>
</style>